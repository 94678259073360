import React from 'react';
import { Link } from 'gatsby';
import RecentReviews from '../components/RecentReviews';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="container">
      <RecentReviews />
      <h3 className="text-center mb-5 mt-5"><Link to="/articles/2">More Articles</Link></h3>
    </div>
  </Layout>
);

export default IndexPage;
